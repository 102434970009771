import type { HTMLAttributeAnchorTarget } from 'react'
import FacebookOutlineIcon from '~/components/primitives/icons/FacebookOutlineIcon'
import InstagramOutlineIcon from '~/components/primitives/icons/InstagramOutlineIcon'
import TwitterOutlineIcon from '~/components/primitives/icons/TwitterOutlineIcon'
import YoutubeOutlineIcon from '~/components/primitives/icons/YoutubeOutlineIcon'

export interface FooterLinkData {
	id: string
	url: string
	title?: string
	linkClass?: string
	attrs?: {
		target?: HTMLAttributeAnchorTarget
		label?: string
	}
}

export interface FooterLinks {
	[key: string]: {
		sections: {
			needHelp: FooterLinkData[]
			about: FooterLinkData[]
			social: FooterLinkData[]
		}
		copy: {
			liveChat: FooterLinkData
			contactUs: FooterLinkData
			regional?: {
				[key: string]: FooterLinkData
			}
		}
	}
}

export const localesWithRegionalLinks = ['en-ca', 'fr-ca']

export const footerLinks: FooterLinks = {
	'en-us': {
		sections: {
			needHelp: [
				{
					id: 'chat-live-now',
					url: 'https://help.underarmour.com/s/?openchat',
					linkClass: 'footer-m-chat',
				},
				{
					id: 'help-center',
					url: 'https://help.underarmour.com/s/ ',
					attrs: {
						target: '_blank',
					},
				},
				{
					id: 'size-guide',
					url: '/t/size-guide/',
				},
				{
					id: 'shipping-and-delivery',
					url: '/t/shipping.html',
				},
				{
					id: 'returns-and-exchanges',
					url: '/en-us/returnlabel/',
				},
				{
					id: 'store-locator',
					url: '/en-us/store-locator/',
				},
				{
					id: 'order-tracking',
					url: '/en-us/track-order/',
				},
				{
					id: 'gift-cards',
					url: '/en-us/gift-card-landing/',
				},
				{
					id: 'sitemap',
					url: '/t/sitemap/',
				},
				{
					id: 'student-discount',
					url: '/en-us/t/unidays/',
				},
			],
			about: [
				{
					id: 'affiliates',
					url: '/t/ua-affiliate-program/',
				},
				{
					id: 'about-us-our-story',
					url: 'https://about.underarmour.com',
				},
				{
					id: 'ua-rewards',
					url: '/t/rewards.html',
				},
				{
					id: 'careers',
					url: 'https://careers.underarmour.com/',
				},
				{
					id: 'ua-community',
					url: 'https://about.underarmour.com/en/Purpose.html',
				},
				{
					id: 'ua-access',
					url: '/t/access-to-sport/',
				},
				{
					id: 'investor-relations',
					url: 'https://about.underarmour.com/en/investors/resources.html',
				},
				{
					id: 'product-recalls',
					url: 'https://productsafety.underarmour.com/',
				},
				{
					id: 'corporate-sales',
					url: '/t/corporate-sales.html',
				},
				{
					id: 'ua-newsroom',
					url: 'https://about.underarmour.com/en/stories.html',
				},
				{
					id: 'do-not-sell-my-personal-information',
					url: 'https://privacy.underarmour.com/s/article/Privacy-Rights-Request-Form?language=en_US',
				},
				{
					id: 'innovation-idea-house',
					url: 'https://underarmourprd.service-now.com/innovationideahouse',
				},
				{
					id: 'ua-blog',
					url: 'https://www.underarmour.com/en-us/t/playbooks/',
					attrs: {
						label: 'ua-blog-label',
					},
				},
			],
			social: [
				{
					id: 'instagram',
					title: 'Instagram',
					url: 'https://www.instagram.com/underarmour/',
				},
				{
					id: 'facebook',
					title: 'Facebook',
					url: 'https://www.facebook.com/UnderArmour/',
				},
				{
					id: 'twitter',
					title: 'Twitter',
					url: 'https://twitter.com/UnderArmour',
				},
				{
					id: 'youtube',
					title: 'Youtube',
					url: 'https://www.youtube.com/user/underarmour',
				},
			],
		},
		copy: {
			liveChat: {
				id: 'chat-now',
				url: 'https://help.underarmour.com/s/?openchat',
			},
			contactUs: {
				id: 'contact-us',
				url: 'https://help.underarmour.com/s',
			},
		},
	},
	'en-ca': {
		sections: {
			needHelp: [
				{
					id: 'help-center',
					url: 'https://help.underarmour.ca/s/?language=en_US',
					attrs: {
						target: '_blank',
					},
				},
				{
					id: 'size-guide',
					url: '/t/size-guide/',
				},
				{
					id: 'shipping-and-delivery',
					url: '/t/shipping.html/',
				},
				{
					id: 'returns-and-exchanges',
					url: '/returnlabel/',
				},
				{
					id: 'store-locator',
					url: '/store-locator/',
				},
				{
					id: 'order-tracking',
					url: '/track-order/',
				},
				{
					id: 'gift-cards',
					url: '/gift-card-landing/',
				},
				{
					id: 'sitemap',
					url: '/t/sitemap/',
				},
				{
					id: 'student-discount',
					url: '/t/unidays/',
				},
			],
			about: [
				{
					id: 'affiliates',
					url: '/t/ua-affiliate-program/',
				},
				{
					id: 'about-us-our-story',
					url: 'https://about.underarmour.com',
				},
				{
					id: 'careers',
					url: 'https://careers.underarmour.com/',
				},
				{
					id: 'ua-community',
					url: 'https://about.underarmour.com/en/Purpose.html',
				},
				{
					id: 'ua-access',
					url: '/t/access-to-sport/',
				},
				{
					id: 'product-recalls',
					url: 'https://productsafety.underarmour.com/',
				},
				{
					id: 'ua-newsroom',
					url: 'https://about.underarmour.com/en/stories.html',
				},
			],
			social: [
				{
					id: 'instagram',
					title: 'Instagram',
					url: 'https://www.instagram.com/underarmour/',
				},
				{
					id: 'facebook',
					title: 'Facebook',
					url: 'https://www.facebook.com/UnderArmour/',
				},
				{
					id: 'twitter',
					title: 'Twitter',
					url: 'https://twitter.com/UnderArmour',
				},
				{
					id: 'youtube',
					title: 'Youtube',
					url: 'https://www.youtube.com/user/underarmour',
				},
			],
		},
		copy: {
			liveChat: {
				id: 'chat-now',
				url: 'https://help.underarmour.ca/s/?openchat',
			},
			contactUs: {
				id: 'contact-us',
				url: 'https://help.underarmour.ca/s/?language=en_US',
			},
			regional: {
				AODAPolicy: {
					id: 'ontario-aoda-policy',
					title: 'Ontario AODA Policy',
					url: 'https://help.underarmour.ca/s/article/AODA-Policy',
				},
				accessibility: {
					id: 'ontario-accessibility',
					title: 'Ontario Multi-Year Accessibility Plan',
					url: 'https://help.underarmour.ca/s/article/Ontario-Multi-Year-Accessibility-Plan?language=en_US',
				},
				billS211: {
					id: 'bill-s-211',
					title: 'Bill S-211',
					url: 'https://help.underarmour.ca/s/article/Modern-Slavery-Statement?language=en_US',
				},
			},
		},
	},
	'fr-ca': {
		sections: {
			needHelp: [
				{
					id: 'help-center',
					url: 'https://help.underarmour.ca/s/?language=fr_CA',
					attrs: {
						target: '_blank',
					},
				},
				{
					id: 'size-guide',
					url: '/t/guide-tailles/',
				},
				{
					id: 'shipping-and-delivery',
					url: '/t/livraison.html/',
				},
				{
					id: 'returns-and-exchanges',
					url: '/returnlabel/',
				},
				{
					id: 'store-locator',
					url: '/store-locator/',
				},
				{
					id: 'order-tracking',
					url: '/track-order/',
				},
				{
					id: 'gift-cards',
					url: '/gift-card-landing/',
				},
				{
					id: 'sitemap',
					url: '/t/sitemap/',
				},
				{
					id: 'student-discount',
					url: '/t/unidays/',
				},
			],
			about: [
				{
					id: 'affiliates',
					url: '/t/ua-affiliate-program/',
				},
				{
					id: 'about-us-our-story',
					url: 'https://about.underarmour.com',
				},
				{
					id: 'careers',
					url: 'https://careers.underarmour.com/',
				},
				{
					id: 'ua-community',
					url: 'https://about.underarmour.com/en/Purpose.html',
				},
				{
					id: 'ua-access',
					url: '/t/acces-au-sport/',
				},
				{
					id: 'product-recalls',
					url: 'https://productsafety.underarmour.com/',
				},
				{
					id: 'ua-newsroom',
					url: 'https://about.underarmour.com/en/stories.html',
				},
			],
			social: [
				{
					id: 'instagram',
					title: 'Instagram',
					url: 'https://www.instagram.com/underarmour/',
				},
				{
					id: 'facebook',
					title: 'Facebook',
					url: 'https://www.facebook.com/UnderArmour/',
				},
				{
					id: 'twitter',
					title: 'Twitter',
					url: 'https://twitter.com/UnderArmour',
				},
				{
					id: 'youtube',
					title: 'Youtube',
					url: 'https://www.youtube.com/user/underarmour',
				},
			],
		},
		copy: {
			liveChat: {
				id: 'chat-now',
				url: 'https://help.underarmour.ca/s/?openchat',
			},
			contactUs: {
				id: 'contact-us',
				url: 'https://help.underarmour.ca/s/?language=fr_CA',
			},
			regional: {
				billS211: {
					id: 'bill-s-211',
					title: 'Bill S-211',
					url: 'https://help.underarmour.ca/s/article/D%C3%89CLARATION-SUR-L-ESCLAVAGE-MODERNE?language=fr_CA',
				},
			},
		},
	},
	'es-mx': {
		sections: {
			needHelp: [
				{
					id: 'chat-live-now',
					url: 'https://help.underarmour.com/s/?openchat',
					linkClass: 'footer-m-chat',
				},
				{
					id: 'help-center',
					url: 'https://ayuda.underarmour.com.mx/s/',
					attrs: {
						target: '_blank',
					},
				},
				{
					id: 'size-guide',
					url: '/t/size-guide/',
				},
				{
					id: 'shipping-and-delivery',
					url: '/t/shipping.html/',
				},
				{
					id: 'returns-and-exchanges',
					url: '/returnlabel/',
				},
				{
					id: 'store-locator',
					url: '/t/store-locator/',
				},
				{
					id: 'order-tracking',
					url: '/track-order/',
				},
				{
					id: 'gift-cards',
					url: '/gift-card-landing/',
				},
				{
					id: 'sitemap',
					url: '/t/sitemap/',
				},
				{
					id: 'student-discount',
					url: '/t/unidays/',
				},
			],
			about: [
				{
					id: 'affiliates',
					url: '/t/ua-affiliate-program/',
				},
				{
					id: 'about-us-our-story',
					url: 'https://about.underarmour.com',
				},
				{
					id: 'careers',
					url: 'https://careers.underarmour.com/',
				},
				{
					id: 'ua-community',
					url: 'https://about.underarmour.com/en/Purpose.html',
				},
				{
					id: 'ua-access',
					url: '/t/access-to-sport/',
				},
				{
					id: 'investor-relations',
					url: 'https://about.underarmour.com/en/investors/resources.html',
				},
				{
					id: 'product-recalls',
					url: 'https://productsafety.underarmour.com/',
				},
				{
					id: 'corporate-sales',
					url: '/t/corporate-sales.html',
				},
				{
					id: 'ua-newsroom',
					url: 'https://about.underarmour.com/en/stories.html',
				},
				{
					id: 'do-not-sell-my-personal-information',
					url: 'https://account.underarmour.com/en-us/data-management',
				},
				{
					id: 'innovation-idea-house',
					url: 'https://underarmourprd.service-now.com/innovationideahouse',
				},
			],
			social: [
				{
					id: 'instagram',
					title: 'Instagram',
					url: 'https://www.instagram.com/underarmour/',
				},
				{
					id: 'facebook',
					title: 'Facebook',
					url: 'https://www.facebook.com/UnderArmour/',
				},
				{
					id: 'twitter',
					title: 'Twitter',
					url: 'https://twitter.com/UnderArmour',
				},
				{
					id: 'youtube',
					title: 'Youtube',
					url: 'https://www.youtube.com/user/underarmour',
				},
			],
		},
		copy: {
			liveChat: {
				id: 'chat-now',
				url: 'https://help.underarmour.com/s/?openchat',
			},
			contactUs: {
				id: 'contact-us',
				url: 'https://help.underarmour.com/s',
			},
		},
	},
	'en-nl': {
		sections: {
			needHelp: [],
			about: [],
			social: [],
		},
		copy: {
			liveChat: {
				id: 'chat-now',
				url: 'https://www.underarmour.nl/en-nl/contact-us/contact-us.html',
			},
			contactUs: {
				id: 'contact-us',
				url: 'https://www.underarmour.nl/en-nl/contact-us/contact-us.html',
			},
		},
	},
}

interface FooterImage {
	id: string
	altText: string
	url?: string
	imageUrl?: string
	src?: string
	icon?: JSX.Element
}

interface FooterImages {
	paymentTypes: Record<string, FooterImage>
	apps: Record<string, FooterImage>
	logo: FooterImage
	social: Record<string, FooterImage>
}

export const footerImages: FooterImages = {
	paymentTypes: {
		mastercard: {
			id: 'mastercard',
			altText: 'Mastercard',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dw5dde1082/images/footer-payment/mastercard.svg',
		},
		discover: {
			id: 'discover',
			altText: 'Discover',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dw88ca8ccb/images/footer-payment/discover.svg',
		},
		visa: {
			id: 'visa',
			altText: 'Visa',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dw68513e7f/images/footer-payment/visa.svg',
		},
		amex: {
			id: 'amex',
			altText: 'American Express',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dw5a9c9c7c/images/footer-payment/amex.svg',
		},
		applePay: {
			id: 'apple-pay',
			altText: 'Apple Pay',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dw589a67a5/images/footer-payment/apple-pay.svg',
		},
		payPal: {
			id: 'pay-pal',
			altText: 'PayPal',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dw66f41406/images/footer-payment/pay-pal.svg',
		},
		klarna: {
			id: 'klarna',
			altText: 'Klarna',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dw60d84e89/images/footer-payment/klarna.svg',
		},
	},
	apps: {
		shop: {
			id: 'shop',
			altText: 'Shop',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dwab247448/images/footer/shop.png',
			url: '/t/shop-app.html',
		},
		mapmy: {
			id: 'map-my-run',
			altText: 'Map My Run',
			imageUrl:
				'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dwc3c8ee9f/images/footer/mmr.png',
			url: 'https://www.mapmyrun.com/',
		},
	},
	logo: {
		id: 'under-armour',
		altText: 'Under Armour',
		imageUrl:
			'https://www.underarmour.com/on/demandware.static/-/Library-Sites-NASharedLibrary/default/dw7789fe42/images/footer/underarmour.svg',
	},
	social: {
		instagram: {
			id: 'instagram',
			altText: 'Instagram',
			icon: <InstagramOutlineIcon size="SM" />,
		},
		facebook: {
			id: 'facebook',
			altText: 'Facebook',
			icon: <FacebookOutlineIcon size="SM" />,
		},
		twitter: {
			id: 'twitter',
			altText: 'Twitter',
			icon: <TwitterOutlineIcon size="SM" />,
		},
		youtube: {
			id: 'youtube',
			altText: 'Youtube',
			icon: <YoutubeOutlineIcon size="SM" />,
		},
	},
}
