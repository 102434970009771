import { getPublicConfig } from '~/lib/client-server/config'

export default function BfxScript() {
	const { url, key, env, mode, brand, log_level: logLevel, enabled } = getPublicConfig().borderfree
	return enabled ? (
		<script
			dangerouslySetInnerHTML={{
				__html: `
    ;(function(e,n,o,t,a){function c(n){e[t]._apiKey=n.key;e[t]._env=n.env;e[t]._logLevel=n.logLevel;
        e[t]._mode=n.mode;e[t]._brand=n.brand;e[t]._cookieFlag=n.cookieFlag}e[t]=c;e[t].l=1*new Date;var i,l;
        i=n.createElement(o);l=n.getElementsByTagName(o)[0];i.async=1;i.src=a;l.parentNode.insertBefore(i,l);
        })(window,document,"script","bfx","${url}");
        bfx({key:'${key}', env:'${env}', mode:'${mode}', brand:'${brand}', logLevel:'${logLevel}'});    
    `,
			}}
		/>
	) : null
}
