'use client'

import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import { useAuthModals } from '~/components/hooks/useAuthModals'
import { useNewVisitorModal, OptInType } from '~/components/hooks/useNewVisitorModal'
import { useQuickAddToCart } from '~/components/hooks/useQuickAddToCart'
import { useWelcomeMat } from '~/components/hooks/useWelcomeMat'
import { EnrollmentSource } from '~/lib/types/loyalty.interface'

const EmailSignupDialog = dynamic(() => import('~/components/shared/EmailSignup/EmailSignupDialog'), { ssr: false })
const WelcomeMatDialog = dynamic(() => import('~/components/shared/WelcomeMatDialog/WelcomeMatDialog'), {
	ssr: false,
})

const QuickAddToCartModal = dynamic(
	() => import('~/components/shared/ProductTile/QuickAddToCartModal/QuickAddToCartModal'),
	{ ssr: false },
)
export function Modals() {
	const { showLoyaltyEnrollmentModal } = useAuthModals()
	const newVisitorModal = useNewVisitorModal()
	const { modalActive, isModal } = useQuickAddToCart()
	const { welcomeMatData } = useWelcomeMat()

	useEffect(() => {
		if (newVisitorModal === OptInType.LOYALTY_SIGNUP) {
			showLoyaltyEnrollmentModal(undefined, EnrollmentSource.ECOMMFLYIN)
		}
	}, [newVisitorModal, showLoyaltyEnrollmentModal])

	return (
		<>
			{isModal && modalActive && <QuickAddToCartModal />}

			{newVisitorModal === OptInType.EMAIL_SIGNUP && <EmailSignupDialog />}

			{welcomeMatData?.shouldShowWelcomeMat && <WelcomeMatDialog {...welcomeMatData} />}
		</>
	)
}
