export default {
	production: {
		AU: {
			url: 'https://www.underarmour.com.au/en-au/',
		},
		CA: {
			url: 'https://www.underarmour.ca/en-ca/',
		},
		KR: {
			url: 'http://www.underarmour.co.kr/?cregion=%2Fen-us',
		},
		SG: {
			url: 'http://www.underarmour.com.sg/en-sg/?cregion=%2Fen-us',
		},
		MX: {
			url: 'https://www.underarmour.com.mx/es-mx/',
		},
		UK: {
			url: 'https://www.underarmour.co.uk/en-gb/',
		},
		US: {
			url: 'https://www.underarmour.com/?country=US&currency=USD',
		},
		TR: {
			url: 'https://www.underarmour.com.tr/tr-tr/',
		},
	},
	staging: {
		AU: {
			url: 'https://stg-ap01.ecm.underarmour.com.au/',
		},
		CA: {
			url: 'https://stg-na04.ecm.underarmour.ca/en-ca/',
		},
		KR: {
			url: 'https://stg-ap01.ecm.underarmour.co.kr/',
		},
		SG: {
			url: 'https://stg-ap01.ecm.underarmour.com.sg/',
		},
		MX: {
			url: 'https://staging-na04.ecm.underarmour.com.mx/es-mx/',
		},
		UK: {
			url: 'https://staging-eu03.ecm.underarmour.co.uk/en-gb/',
		},
		US: {
			url: 'https://stg-na04.ecm.underarmour.com/',
		},
		TR: {
			url: 'https://www.underarmour.com.tr/tr-tr/',
		},
	},
	development: {
		AU: {
			url: 'https://dev-ap01.ecm.underarmour.com.au/',
		},
		CA: {
			url: 'https://dev-na04.ecm.underarmour.com/en-ca/',
		},
		KR: {
			url: 'https://dev-ap01.ecm.underarmour.co.kr/',
		},
		SG: {
			url: 'https://dev-ap01.ecm.underarmour.com.sg/',
		},
		MX: {
			url: 'https://dev-na04.ecm.underarmour.com.mx/es-mx/',
		},
		UK: {
			url: 'https://development-eu03.ecm.underarmour.co.uk/en-gb/',
		},
		US: {
			url: 'https://dev-na04.ecm.underarmour.com/en-us/',
		},
		TR: {
			url: 'https://www.underarmour.com.tr/tr-tr/',
		},
	},
	load: {
		AU: {
			url: 'https://prod-ua002.ecm.underarmour.com.au/en-au/',
		},
		CA: {
			url: 'https://load-ua03.ecm.underarmour.ca/en-ca/',
		},
		SG: {
			url: 'https://prod-ua002.ecm.underarmour.com.sg/en-sg/',
		},
		UK: {
			url: 'https://prod-eu02.ecm.underarmour.co.uk/en-gb/',
		},
		US: {
			url: 'https://load-ua03.ecm.underarmour.com/en-us/',
		},
		DE: {
			url: 'https://production-au.sfcc.ua-ecm.com/de-de/',
		},
	},
}
