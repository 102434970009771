'use client'

import { useMemo } from 'react'
import Image from '~/components/primitives/Image'
import { useLocale } from '~/components/hooks/useLocale'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { getPublicConfig } from '~/lib/client-server/config'
import { footerImages } from './footer-data'
import styles from './Footer.module.scss'

export default function PaymentMethods() {
	// TODO: Can this `featureFlag` data be retrieved without Context? If so, we can use RSCs. Maybe we can do this
	// if the `commerceConfiguration`'s GQL query in the `TopLevelRootLayout` is cached?
	const featureFlags = useFeatureFlags()
	const locale = useLocale()

	const paymentMethods = useMemo(() => {
		const config = getPublicConfig()
		const paymentTypes: string[] = config.credit_cards_accepted[locale].slice() ?? []

		paymentTypes.push('payPal')
		if (config.apple_pay.locales.includes(locale)) paymentTypes.push('applePay')
		if (featureFlags?.isKlarnaEnabled) paymentTypes.push('klarna')

		return paymentTypes
	}, [locale, featureFlags?.isKlarnaEnabled])

	return (
		<ul className={styles['footer-payment-methods']} aria-labelledby="footer-payment-methods-label">
			{Object.keys(footerImages.paymentTypes)
				.filter((key) => paymentMethods.includes(key))
				.map((item) => (
					<li key={footerImages.paymentTypes[item].id}>
						<Image
							src={footerImages.paymentTypes[item].imageUrl as string}
							width={40}
							height={40}
							alt={footerImages.paymentTypes[item].altText}
						/>
					</li>
				))}
		</ul>
	)
}
