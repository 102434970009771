import { useCallback, useRef } from 'react'

export type EffectRef<E extends HTMLElement = HTMLElement> = (element: E | null) => void

export type RefCallback<E extends HTMLElement = HTMLElement> = (element: E) => (() => void) | void

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

/**
 * This hook returns a callback function, pass it as `ref` prop to any DOM element to run callback on element mount.
 *
 * Callback can return a clean-up function like `useEffect`'s callback to clean up side effects.
 *
 * A native callback ref may receive element argument as `null`, however `useEffectRef` handles this case internally,
 * only `HTMLElement` node is passed to callback.
 *
 * ```typescript
 * export type EffectRef<E extends HTMLElement = HTMLElement> = (element: E | null) => void;
 *
 * export type RefCallback<E extends HTMLElement = HTMLElement> = (element: E) => (() => void) | void;
 *
 * export function useEffectRef<E extends HTMLElement = HTMLElement>(callback: RefCallback<E>): EffectRef<E>;
 * ```
 *
 * Unlike `useRef` which is not responsive to element change, this hook provides ability to observe any element's mount and unmount.
 *
 * @template E The type of the element to be passed to the callback.
 * @param callback The callback function to be called with the element.
 * @returns The effect ref function that can be passed to the `useEffect` hook.
 *
 * @example
 * ```typescript
 * import React, { useState, useCallback, createElement } from "react";
 * import { Select } from "antd";
 * import { useEffectRef } from "~/components/hooks/useEffectRef";
 *
 * export default () => {
 *     const [tag, setTag] = useState("div");
 *     const [message, setMessage] = useState("");
 *     const updateMessage = useCallback(
 *         (element) =>
 *             setMessage(
 *             `Root element is changed to <${element.nodeName.toLowerCase()}>`
 *             ),
 *         []
 *     );
 *     const ref = useEffectRef(updateMessage);
 *     return (
 *         <div className="effect-select">
 *             <Select value={tag} onChange={setTag} style={{ width: 200, marginBottom:20 }}>
 *                     <Select.Option value="div">div</Select.Option>
 *                     <Select.Option value="section">section</Select.Option>
 *                     <Select.Option value="header">header</Select.Option>
 *                     <Select.Option value="footer">footer</Select.Option>
 *             </Select>
 *             {createElement(tag, { ref }, <p style={{ color: 'red' }}>{message}</p>)}
 *         </div>
 *     );
 * };
 * ```
 */
export function useEffectRef<E extends HTMLElement = HTMLElement>(callback: RefCallback<E>): EffectRef<E> {
	const disposeRef = useRef<() => void>(noop)
	const effect = useCallback(
		(element: E | null) => {
			disposeRef.current()
			// To ensure every dispose function is called only once.
			disposeRef.current = noop

			if (element) {
				const dispose = callback(element)

				if (typeof dispose === 'function') {
					disposeRef.current = dispose
				}
				// Have an extra type check to work with javascript.
				else if (dispose !== undefined) {
					console.warn('Effect ref callback must return undefined or a dispose function')
				}
			}
		},
		[callback],
	)

	return effect
}
