'use client'

import Link from 'next/link'
import { useCallback } from 'react'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useEffectRef } from '~/components/hooks/useCallbackRef'

interface HelpCenterLinkProps extends React.ComponentProps<typeof Link> {
	href: string
	source: string
}

/*
 * TODO: It would probably be more advantageous to register a delegated, `document`-level event listener
 * to track these kinds of click events if possible. (If we execute the event handler during the `CAPTURE` phase,
 * this should be easily doable.) Then we can delete this component altogether, and it will be much easier
 * to use Server Components.
 */

/**
 * A component that renders a link to the help center and tracks clicks using the analytics manager.
 * @param props The props for the component.
 * @param props.href The URL of the help center page.
 * @param props.source The source of the help center link. Provided to the datalayer prop `navigation_structure`
 * @param attrs The additional attributes to be passed to the `Link` component.
 * @returns The rendered `Link` component with the `updateMessage` effect ref.
 * @example
 * // Example usage:
 * <HelpCenterLink href="https://example.com/help" source="footer">
 *   Help Center
 * </HelpCenterLink>
 */
export function HelpCenterLink({ href, source, ...attrs }: HelpCenterLinkProps) {
	const { analyticsManager } = useAnalytics()

	const updateMessage = useCallback(
		/**
		 * A callback function that adds a click event listener to the link element and tracks the click using the analytics manager.
		 * @param element The link element to be updated.
		 * @returns A dispose function that removes the click event listener from the link element.
		 */
		(element: HTMLAnchorElement) => {
			function handleClick() {
				analyticsManager.fireClickedHelpCenterLink(source)
			}
			if (element) {
				element.addEventListener('click', handleClick)
			}
			return () => {
				if (element) {
					element.removeEventListener('click', handleClick)
				}
			}
		},
		[analyticsManager, source],
	)

	const ref = useEffectRef(updateMessage)

	return <Link ref={ref} href={href} {...attrs} />
}
