'use client'

import { useCacheData } from '~/components/hooks/useCacheData'
import { getPublicConfig } from '~/lib/client-server/config'

/**
 * Renders application details (version, etc.)
 * @returns
 */
export function AppDetails() {
	const cacheData = useCacheData()
	const cacheString = cacheData.reduce(
		(acc, data) => `${acc}\nArea: ${data.id}\nTime: ${new Date(data.time).toLocaleString()}`,
		'Cache Data',
	)

	return (
		<div>
			{/* eslint-disable-next-line local-rules/disallow-untranslated-literals-in-jsx */}
			<a title={cacheString}>{`v${getPublicConfig().app.version}`}</a>
		</div>
	)
}
