'use client'

import { type PropsWithChildren, Suspense, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { AuthModalProvider } from '../hooks/useAuthModals'
import AbTestProvider from '../providers/AbTestProvider/AbTestProvider'
import CartProvider from '../providers/CartProvider'
import { LayoutMeasurementProvider } from '../providers/LayoutMeasurementProvider/LayoutMeasurementProvider'
import LoyaltyProvider from '../providers/LoyaltyProvider'
import { PreferredSizesProvider } from '../providers/PreferredSizesProvider/PreferredSizesProvider'
import QuickAddToCartProvider from '../providers/QuickAddToCartProvider/QuickAddToCartProvider'
import ThirdPartyPaymentProvider from '../providers/ThirdPartyPaymentProvider/ThirdPartyPaymentProvider'
import WishlistProvider from '../providers/WishlistProvider/WishlistProvider'
import Loading from '../shared/Loading/Loading'
import { setupDataDog } from '~/lib/client-only/datadog'
import { getPublicConfig } from '~/lib/client-server/config'
import BfxScript from '../thirdparty/BfxScript/BfxScript'
import { Modals } from './NavbarLayout/Modals'
import dynamic from 'next/dynamic'

const Toolkit = dynamic(() => import('~/components/Toolkit/Toolkit'), { ssr: false })

interface ClientLayoutProps {
	locale: string
	footer: React.ReactElement
}

export function ClientLayout({ locale, footer, children }: PropsWithChildren<ClientLayoutProps>) {
	useEffect(() => {
		setupDataDog(locale)
	}, [locale])

	return (
		<>
			<AbTestProvider>
				<CartProvider>
					<LoyaltyProvider>
						<WishlistProvider>
							<ThirdPartyPaymentProvider>
								<QuickAddToCartProvider>
									<PreferredSizesProvider>
										<LayoutMeasurementProvider>
											<IntlProvider locale={locale} defaultLocale={'en-us'}>
												<AuthModalProvider>
													{children}
													{footer}
													<BfxScript />
													<Suspense>
														<Modals />
													</Suspense>

													{getPublicConfig().toolkit.enabled && <Toolkit />}

													<Suspense>
														<Loading />
													</Suspense>
												</AuthModalProvider>
											</IntlProvider>
										</LayoutMeasurementProvider>
									</PreferredSizesProvider>
								</QuickAddToCartProvider>
							</ThirdPartyPaymentProvider>
						</WishlistProvider>
					</LoyaltyProvider>
				</CartProvider>
			</AbTestProvider>
		</>
	)
}
