'use client'

// TODO: This can probably be converted to a Server Component if we use Server Actions for the `EmailForm`
import dynamic from 'next/dynamic'
import LazyLoad from 'react-lazy-load'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import styles from './EmailFooter.module.scss'

const EmailForm = dynamic(() => import('~/components/shared/EmailSignup/EmailForm/EmailForm'), { ssr: false })

function EmailFooter() {
	const formatMessage = useFormatMessage()

	return (
		<>
			<div className={styles['signup-title']}>{formatMessage('stay-in-the-loop')}</div>
			<div className={styles['email-signup-container']}>
				<LazyLoad>
					<EmailForm />
				</LazyLoad>
			</div>
		</>
	)
}

export default EmailFooter
