import React, { useCallback, useMemo, useState } from 'react'
import type { QuickAddToCartModalParamsInterface } from '~/components/hooks/useQuickAddToCart'
import { QuickAddToCartContext } from '~/components/hooks/useQuickAddToCart'

function QuickAddToCartProvider({ children }: React.PropsWithChildren): React.ReactElement {
	const [modalParams, setModalParams] = useState<QuickAddToCartModalParamsInterface>({ productId: '' })
	const [modalActive, setModalActive] = useState(false)
	// This state was added to allow the QuickAddToCart to be used with or without a modal. This is needed because the QATC lives on every page, and default settings in the component were not always followed.
	const [isModal, setIsModal] = useState(true)

	const openModal = useCallback((newModalParams) => {
		setModalParams(newModalParams)
		setModalActive(true)
	}, [])

	const contextValue = useMemo(() => {
		return { modalParams, openModal, modalActive, setModalActive, isModal, setIsModal }
	}, [modalParams, openModal, modalActive, isModal])

	return <QuickAddToCartContext.Provider value={contextValue}>{children}</QuickAddToCartContext.Provider>
}

export default QuickAddToCartProvider
