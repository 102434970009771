'use client'

import { useEffect, useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import { getPathSegmentsAfterDescriptors } from '~/lib/utils'
import { ensureBoolean } from '~/types/strict-null-helpers'
import { useAnalytics } from './useAnalytics'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import useNavigation from './useNavigation'
import { useSearchParams } from 'next/navigation'

export enum OptInType {
	NONE,
	EMAIL_SIGNUP,
	LOYALTY_SIGNUP,
}

interface Affinities {
	isLoyaltyEnabled: boolean
	isZipGatingEnabled: boolean
	isLoggedIn: boolean
	isLoyaltyEnrolled: boolean
	urlSegments: string[]
}

function getMostAppropriateExperience({
	isLoyaltyEnabled,
	isZipGatingEnabled,
	isLoggedIn,
	isLoyaltyEnrolled,
	urlSegments,
}: Affinities): OptInType {
	/**
	 * Show nothing
	 * a. suppress modal on page
	 * b. already registered/enrolled in loyalty
	 * c. on reset password flow
	 */
	const isFullLaunch = isLoyaltyEnabled && !isZipGatingEnabled
	if (
		urlSegments.some((segment) => ['rewards.html', 'setpassword'].includes(segment)) ||
		(isFullLaunch && isLoyaltyEnrolled)
	) {
		return OptInType.NONE
	}

	/**
	 * Show UA Rewards Enrollment Signup if
	 * 1. Loyalty is Enabled and Zip Gating (Pilot) is Disabled, and
	 * 	a. customer not logged in (eg. guest)
	 * 	b. or, customer is logged in and is not enrolled in loyalty
	 */

	if (isFullLaunch && (!isLoggedIn || !isLoyaltyEnrolled)) {
		return OptInType.LOYALTY_SIGNUP
	}

	/**
	 * Fallback to Email Signup
	 */
	return OptInType.EMAIL_SIGNUP
}

/**
 * This hook will do the work of determining which new visitor experience to show the customer and when.
 * It checks the 'newVisitorCookie' value looking for the value 'first' - if found, it will
 * return true.  If not there it will create the cookie and set it to 'first'.  If the value is there and
 * set to 'second' then it will return false.  This is based on the way SFCC does it.
 * @returns the OptInType
 */
export function useNewVisitorModal(): OptInType {
	// Set if user can register email
	const [modalState, setModalState] = useState<OptInType>(OptInType.NONE)
	const { analyticsManager } = useAnalytics()
	const { user } = useSession()
	const featureFlags = useFeatureFlags()
	const isLoyaltyEnabled = ensureBoolean(featureFlags?.isLoyaltyEnabled)
	const isZipGatingEnabled = ensureBoolean(featureFlags?.isLoyaltyPilotEnabled)
	const readonlySearchParams = useSearchParams()
	const router = useNavigation({ readonlySearchParams })
	const urlSegments = getPathSegmentsAfterDescriptors(router.asPath)
	const lastRoute = useRef<string | undefined>()

	useEffect(() => {
		const cookies = new Cookies()
		const cookieExists = cookies.get('newVisitorCookie')

		// Wait for session and loyalty data
		if (!user || !featureFlags || lastRoute.current === router.asPath) {
			// loyalty is a different kinda of modal and is fired via a function call on MainLayout vs <... /> rendering
			// need to reset back to NONE so it will stop showing repeaditly
			if (cookieExists === 'second' && modalState === OptInType.LOYALTY_SIGNUP) setModalState(OptInType.NONE)
			return
		}

		lastRoute.current = router.asPath

		const experienceType = getMostAppropriateExperience({
			isLoggedIn: user.isRegistered,
			isLoyaltyEnrolled: user.isLoyalty,
			isLoyaltyEnabled,
			isZipGatingEnabled,
			urlSegments,
		})

		// 30 Day expiration
		const expires = new Date()
		expires.setDate(expires.getDate() + 30)

		if (cookieExists === 'first') {
			cookies.set('newVisitorCookie', 'second', {
				path: '/',
				expires,
			})
			setModalState(experienceType)
			analyticsManager.fireModalOpened({
				site_modal: 'new-visitor-modal',
			})
		} else if (cookieExists !== 'first' && cookieExists !== 'second') {
			cookies.set('newVisitorCookie', 'first', {
				path: '/',
				expires,
			})
		}
	}, [
		analyticsManager,
		featureFlags,
		isLoyaltyEnabled,
		isZipGatingEnabled,
		user,
		urlSegments,
		lastRoute,
		router.asPath,
		modalState,
	])

	return modalState
}
