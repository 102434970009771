'use client'

import { useLazyQuery, useMutation } from '@apollo/client'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import {
	AddProductToWishListDocument,
	GetWishListDocument,
	RemoveProductFromWishListDocument,
} from '~/graphql/generated/uacapi/type-document-node'
import { getDefaultSizeFromQuery, getInitialSize, getMasterProductId, isMasterProduct } from '~/lib/products'
import type { FavoritesAddActionData, FavoritesRemoveActionData } from '~/lib/types/analytics.interface'
import type { ClientProductData, ClientProductDetail, ClientProductVariantDetail } from '~/lib/types/product.interface'
import type { MappedWishListProduct, WishlistItem, WishlistItems } from '~/lib/types/wishlist.interface'
import { mapWishListProducts } from '~/lib/wishlist'
import { extractSearchParamsFromDynamicParameters, safeError } from '~/lib/utils'
import { createClientLogger } from '~/lib/logger'
import { useParams } from 'next/navigation'

const logger = createClientLogger('wishlist')

type SupportedProductTypes = ClientProductData | ClientProductDetail

interface FindWishlistProductArgs {
	product?: SupportedProductTypes
	selectedVariant?: ClientProductVariantDetail
}

interface FindWishlistProductResponse {
	isSavedItem: boolean
	hasMasterProduct: WishlistItem['hasMasterProduct']
	nodeId: WishlistItem['nodeId'] | undefined
}

export interface WishlistContextInterface {
	loadingWishlist: boolean
	wishListInitialized: boolean
	removingWishListItem: boolean
	wishlistProducts: MappedWishListProduct[]
	addProductToWishlist(
		product: SupportedProductTypes,
		color: string,
		variant?: ClientProductVariantDetail,
	): Promise<void>
	removeProductFromWishlist(
		product: SupportedProductTypes | ClientProductVariantDetail | undefined,
		color: string,
	): Promise<void>
	setWishListProducts(products: MappedWishListProduct[]): void
	findWishlistProduct({ product, selectedVariant }: FindWishlistProductArgs): FindWishlistProductResponse
}

export const defaultWishlistProvider: WishlistContextInterface = {
	loadingWishlist: true,
	wishListInitialized: false,
	removingWishListItem: false,
	wishlistProducts: [],
	addProductToWishlist: async () => {
		throw new Error('addProductToWishlist() not implemented. You must include a WishlistProvider in your app.')
	},
	removeProductFromWishlist: () => {
		throw new Error('removeProductFromWishlist() not implemented. You must include a WishlistProvider in your app.')
	},
	setWishListProducts: () => {
		throw new Error('setWishListProducts() not implemented. You must include a WishlistProvider in your app.')
	},
	findWishlistProduct: () => {
		throw new Error('findWishlistProduct() not implemented. You must include a WishlistProvider in your app.')
	},
}

export const WishlistContext = createContext(defaultWishlistProvider)

export function useWishlist() {
	return useContext(WishlistContext)
}

function WishlistProvider({ children }: React.PropsWithChildren): React.ReactElement {
	const [wishlistProducts, setWishListProducts] = useState<MappedWishListProduct[]>([])
	const { analyticsManager } = useAnalytics()
	const { user } = useSession()
	const wishListInitialized = useRef(false)
	// TODO: Does this provide all params we expect?
	// Using useNavigation(useSearchParams) results in: "Entire page deopted into client-side rendering"
	const appRouterDynamicSegments = useParams()
	const queryAsUrlSearchParams = extractSearchParamsFromDynamicParameters(appRouterDynamicSegments)

	const [getWishlistQuery, { loading: getWishlistLoading }] = useLazyQuery(GetWishListDocument, {
		fetchPolicy: 'no-cache',
		variables: { first: 200, after: null },
		onCompleted: (data) => {
			wishListInitialized.current = true
			const edges = data.viewer?.wishList?.productListItems.edges || []
			setWishListProducts(mapWishListProducts(edges))
		},
	})

	const [addToWishList] = useMutation(AddProductToWishListDocument, {
		onCompleted: (data) => {
			if (!data) return
			const products = mapWishListProducts(data.addProductToWishList.wishList.productListItems.edges)
			setWishListProducts(products)
		},
	})

	const addProductToWishlist = useCallback(
		async (product: SupportedProductTypes, color: string, variant?: ClientProductVariantDetail) => {
			if (!product.style) {
				logger.warn('Cannot add product to wishlist, product style is missing: %o', product)
				return
			}

			const productId = variant ? variant.id : getMasterProductId(product.style)
			if (productId) {
				try {
					const result = await addToWishList({ variables: { productId, numberOfItemsToReturn: 200 } })
					// Check for success
					if (result.data?.addProductToWishList?.wishList?.productListItems?.edges) {
						const products = mapWishListProducts(result.data.addProductToWishList.wishList.productListItems.edges)
						setWishListProducts(products)
					} else if (result.errors) {
						logger.warn('Cannot add product to wishlist, result has errors: %o', result.errors)
					} else {
						logger.warn('Cannot add product to wishlist, result is missing: %o', result)
					}
				} catch (err) {
					logger.warn(safeError(err).message)
				}
			} else {
				logger.warn('Cannot add product to wishlist, product id is missing: %o', product)
			}
			const analyticsProduct: FavoritesAddActionData['products'][0] = {
				product_style: product.style,
				product_color: `${product.style}-${color}`,
			}
			if (product.sku) {
				analyticsProduct.product_sku = product.sku
			} else if (variant?.sku) {
				analyticsProduct.product_sku = variant.sku
			} else if (product.style && color && variant?.size) {
				analyticsProduct.product_sku = `${product.style}-${color}-${variant.size}`
			} else if (product.style && color && product.sizes?.length) {
				const { size: preferredSize, extendedSize: preferredExtendedSize } =
					getDefaultSizeFromQuery(queryAsUrlSearchParams)
				const maybeSize = getInitialSize(product, color, preferredSize, preferredExtendedSize)
				if (maybeSize) {
					analyticsProduct.product_sku = `${product.style}-${color}-${maybeSize.size}`
				}
			}
			analyticsManager.fireFavoritesAdd({
				products: [analyticsProduct],
			})
		},
		[analyticsManager, addToWishList, queryAsUrlSearchParams],
	)

	const [removeFromWishList, { loading: removingWishListItem }] = useMutation(RemoveProductFromWishListDocument, {
		onCompleted: (data) => {
			if (!data) return
			const products = mapWishListProducts(data.removeProductFromWishList.wishList.productListItems.edges)
			setWishListProducts(products)
		},
	})

	useEffect(() => {
		if (user?.uacapiAccessToken) {
			getWishlistQuery()
		}
	}, [user?.uacapiAccessToken, getWishlistQuery])

	const wishlistItems = useMemo(() => {
		const wishlistItemByStyle = new Map()

		wishlistProducts.forEach((product) => {
			if (!product.style) return

			const { style, id, nodeId, __typename } = product
			const productEntry = { style, id, nodeId, __typename }
			const hasMasterProduct = isMasterProduct(product)

			if (!wishlistItemByStyle.has(style)) {
				wishlistItemByStyle.set(style, { hasMasterProduct, entries: [productEntry] })
			} else {
				const currentStyle = wishlistItemByStyle.get(style)
				currentStyle.entries.push(productEntry)

				if (hasMasterProduct) currentStyle.hasMasterProduct = true
			}
		})
		return wishlistItemByStyle
	}, [wishlistProducts])

	/**
	 * Some wishlist products are saved with VariantProduct ids. In this case, deriving if the PDp page we are on
	 * contains this id needs to be done by traversing the variant collection if it exists. Otherwise, we need to
	 * build a MasterProduct id based on the style and do a comparison to the wishlist productid.
	 */

	const findComparableItem = (
		refObj: WishlistItems,
		compareKey: string,
		compareValue: string | undefined,
	): string | undefined => {
		let foundItem: { nodeId: string } | undefined
		if (refObj.entries && refObj?.entries.length) {
			foundItem = refObj.entries.find((item) => item[compareKey] === compareValue)
		}
		return foundItem?.nodeId
	}

	// This function is used for 2 things:
	// 1: To find the nodeId of a MasterProduce or Variant if it exists in the wishlist. This is useful for processes like removing an item from the wishlist. We need the specific nodeId rather than productId to remove the item otherwise we will remove all items with the same productId.
	// 2: By finding a specific MasterProduct or Variant, we are then able to implicitely display the correct icon state for the <FavoriteButton /> component. This is done with the return property `isSavedItem`.
	const findWishlistProduct = useCallback(
		({ product, selectedVariant }: FindWishlistProductArgs) => {
			const currentInput = product || selectedVariant
			let nodeId: string | undefined
			let hasMasterProduct: boolean | undefined

			//
			if (currentInput?.nodeId) {
				nodeId = currentInput.nodeId
			} else {
				const currentStyle = wishlistItems.get(currentInput?.style)

				if (currentStyle && currentStyle.hasMasterProduct) {
					nodeId = findComparableItem(currentStyle, '__typename', 'MasterProduct')
					hasMasterProduct = true
				} else if (currentStyle && !currentStyle.hasMasterProduct) {
					nodeId = findComparableItem(currentStyle, 'id', currentInput?.id)
					hasMasterProduct = false
				}
			}
			return { isSavedItem: !!nodeId, hasMasterProduct, nodeId }
		},
		[wishlistItems],
	)

	const removeProductFromWishlist = useCallback(
		async (product: SupportedProductTypes, color: string) => {
			if (!product.style) return
			const { nodeId } = findWishlistProduct({ product: product as FindWishlistProductArgs['product'] })
			await removeFromWishList({ variables: { productId: nodeId as string, first: 200 } })
			const analyticsProduct: FavoritesRemoveActionData['products'][0] = {
				product_style: product.style,
				product_color: `${product.style}-${color}`,
			}
			if (product.sku) {
				analyticsProduct.product_sku = product.sku
			} else if (product.style && color && product.sizes?.length) {
				const { size: preferredSize, extendedSize: preferredExtendedSize } =
					getDefaultSizeFromQuery(queryAsUrlSearchParams)
				const maybeSize = getInitialSize(product, color, preferredSize, preferredExtendedSize)
				if (maybeSize) {
					analyticsProduct.product_sku = `${product.style}-${color}-${maybeSize.size}`
				}
			}
			analyticsManager.fireFavoritesRemove({
				products: [analyticsProduct],
			})
		},
		[findWishlistProduct, removeFromWishList, analyticsManager, queryAsUrlSearchParams],
	)

	const contextValue = useMemo(() => {
		return {
			loadingWishlist: getWishlistLoading,
			wishListInitialized: wishListInitialized.current,
			removingWishListItem,
			addProductToWishlist,
			removeProductFromWishlist,
			wishlistProducts,
			setWishListProducts,
			findWishlistProduct,
		}
	}, [
		getWishlistLoading,
		removingWishListItem,
		addProductToWishlist,
		removeProductFromWishlist,
		wishlistProducts,
		setWishListProducts,
		findWishlistProduct,
	])

	return <WishlistContext.Provider value={contextValue}>{children}</WishlistContext.Provider>
}

export default WishlistProvider
