/* eslint-disable no-underscore-dangle */
import type { FullSiteList, RegionDetails, StorefrontSiteDetails } from '~/graphql/generated/uacapi/type-document-node'
import { ensureArray } from '~/types/strict-null-helpers'

export default class SiteManager {
	private _continentDisplayOrder: string[]
	private _sitesByContinent: RegionDetails[]
	private _siteList: StorefrontSiteDetails[]

	constructor(fullSiteList: FullSiteList) {
		if (fullSiteList.regions && fullSiteList.displayOrder) {
			this._continentDisplayOrder = ensureArray(fullSiteList.displayOrder).filter((d) => !!d) as string[]
			this._sitesByContinent = fullSiteList.regions
			this._siteList = Object.values(fullSiteList.regions).reduce(
				(acc, r) => acc.concat(r.siteDetailsList),
				[] as StorefrontSiteDetails[],
			)
		} else {
			this._continentDisplayOrder = []
			this._sitesByContinent = []
			this._siteList = []
		}
	}

	get continentDisplayOrder() {
		return this._continentDisplayOrder
	}

	get sitesByContinent() {
		return this._sitesByContinent
	}

	public getSitesInContinent(continent: string) {
		return this._sitesByContinent.find((c) => c.name === continent)
	}

	public isBorderFreeSite(countryCode: string): boolean {
		return this.getSiteData(countryCode)?.url.includes('country=') || false
	}

	public getSiteData(countryCode: string) {
		return this._siteList.find((s) => s.countryCode === countryCode)
	}
	public getCountryName(countryCode: string) {
		return this.getSiteData(countryCode)?.name
	}
}
