import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { elementPropsToCacheData } from '~/lib/client-only/caching'
import type { CacheData } from '~/lib/client-server/caching'

export function useCacheData() {
	const [cachedData, setCachedData] = useState<CacheData[]>([])
	const pathname = usePathname()

	useEffect(() => {
		// Get rendered cache data
		const cachedElements = document.querySelectorAll('[data-cache-id]')
		const cacheData: CacheData[] = []
		cachedElements.forEach((element) => {
			cacheData.push(elementPropsToCacheData(element))
		})

		setCachedData(cacheData)
	}, [pathname])

	return cachedData
}
