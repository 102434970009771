import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

function TwitterOutlineIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(props)}>
			<circle cx="8" cy="8" r="7.5" stroke="currentColor" />
			<path
				d="M6.62709 11.9559C9.95259 11.9559 11.7713 9.20078 11.7713 6.81166C11.7713 6.73366 11.7696 6.65578 11.7661 6.57803C12.1199 6.3215 12.4253 6.00406 12.668 5.64053C12.3379 5.78658 11.988 5.88251 11.6296 5.92516C12.007 5.69916 12.2894 5.34373 12.4242 4.92503C12.0694 5.13557 11.6812 5.28395 11.2763 5.36378C10.7162 4.76632 9.82456 4.6197 9.1026 5.00634C8.38064 5.39298 8.00849 6.21641 8.19534 7.01378C6.74143 6.94118 5.38674 6.25457 4.46859 5.12491C3.9887 5.95096 4.23369 7.00777 5.02809 7.53841C4.741 7.53015 4.46011 7.45298 4.20909 7.31341C4.20909 7.32091 4.20909 7.32841 4.20909 7.33666C4.20954 8.19733 4.81633 8.93852 5.65997 9.10891C5.39369 9.18165 5.11426 9.1923 4.84322 9.14003C5.07999 9.87594 5.75782 10.3804 6.53072 10.3959C5.89053 10.8978 5.10022 11.17 4.28672 11.1688C4.14261 11.1689 3.99862 11.1606 3.85547 11.144C4.68229 11.675 5.64445 11.9569 6.62709 11.9559Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default TwitterOutlineIcon
