import { datadogRum } from '@datadog/browser-rum'
import { getPublicConfig } from '../client-server/config'

export function setupDataDog(locale: string) {
	const rumEnabled = getPublicConfig().metrics.dd_rum_enabled
	if (rumEnabled) {
		datadogRum.init({
			applicationId: getPublicConfig().metrics.dd_aplication_id,
			clientToken: getPublicConfig().metrics.dd_client_token,
			site: 'datadoghq.com',
			service: 'seabiscuit',
			env: getPublicConfig().metrics.dd_environment,
			version: getPublicConfig().app.version,
			sampleRate: getPublicConfig().metrics.dd_session_sample_rate,
			sessionReplaySampleRate: getPublicConfig().metrics.dd_session_replay_sample_rate,
			trackInteractions: true,
			trackResources: true,
			trackLongTasks: true,

			defaultPrivacyLevel: 'mask-user-input',
		})

		if (getPublicConfig().metrics.dd_replay_enabled_locales.includes(locale)) {
			// NOTE: This will only record if the sample rate for replays is > 0
			datadogRum.startSessionReplayRecording()
		} else {
			// NOTE: In cases where the user switches locales without a page refresh (not sure if that CAN happen)
			//	this will ensure we're not recording replays for the wrong locale
			datadogRum.stopSessionReplayRecording()
		}
	}
}
