'use client'

import { useEffect, useMemo, useState } from 'react'
import Cookies from 'universal-cookie'
import { getWelcomeMatInfo, type WelcomeMatInfo } from '~/lib/client-only/welcome-mat'
import { getCountryCodeByLocale } from '~/lib/i18n/locale'
import logger from '~/lib/logger'
import { firstStringValue } from '~/lib/utils'
import { useFrontendConfiguration } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { useLocale } from './useLocale'
import useNavigation from './useNavigation'
import { useSearchParams } from 'next/navigation'

interface UseWelcomeMatReturnValue {
	welcomeMatData: WelcomeMatInfo | undefined
}

/**
 * This hook is used to determine whether the welcome mat should
 * be shown. It will attempt to get the information about the country of origin and
 * the information about the URLs associated with all countries, then return the
 * directive to the client. For example, if the user is visiting the US site
 * from Hungary and the user has not been shown the welcome mat before in this
 * country, then it will indicate that the welcome mat should be shown with
 * information about what should be displayed within the modal.
 */
export function useWelcomeMat(): UseWelcomeMatReturnValue {
	const locale = useLocale()
	const readonlySearchParams = useSearchParams()
	const { queryAsParsedUrlQuery } = useNavigation({ readonlySearchParams })
	const { commerceConfig } = useFrontendConfiguration() || {}

	const [welcomeMatData, setWelcomeMatData] = useState<WelcomeMatInfo>()

	useEffect(() => {
		if (commerceConfig?.sitesFullList) {
			const cookies = new Cookies()
			const params = new URLSearchParams()
			Object.entries(queryAsParsedUrlQuery ?? {}).forEach(([key, value]) => params.append(key, firstStringValue(value)))
			params.append('country', getCountryCodeByLocale(locale))
			setWelcomeMatData(getWelcomeMatInfo(cookies, params, commerceConfig?.sitesFullList, logger))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale, commerceConfig?.sitesFullList])

	return useMemo(() => ({ welcomeMatData }), [welcomeMatData])
}
