import type { Logger } from 'pino'
import type Cookies from 'universal-cookie'
import type { FullSiteList } from '~/graphql/generated/uacapi/type-document-node'
import { firstStringValue } from '~/lib/utils'
import globalAccessCountries from '../preferences/global-access-countries'
import SiteManager from '../site-manager'
import { getStoredUserPreferenceData } from '~/lib/client-only/auth/storage'

/**
 * This is set by the middleware and pulls the country from the geolocation provided by the CDN/Edge/Infrastructure
 */
const COOKIE_CLIENT_COUNTRY = 'ua-geo-data'

/**
 * This is set by the BFX library but we access it to see what BFX thinks is the user's country.
 */
const COOKIE_BFX_COUNTRY = 'bfx.country'

/**
 * This is the country specific in the URL.  When a user is coming from a country other than the US but wants to use the US site,
 * we use this to indicate that source country.
 */
const QUERY_COUNTRY = 'country'
export interface WelcomeMatInfo {
	isShipCountryGlobalAccess: boolean
	isBorderFreeEnabled: boolean

	// This is the site the user is currently browsing
	thisCountry: string
	thisCountryUrl: string

	// This is the ship to country information
	shipToCountry: string
	shipToUrl: string

	// This is the value used to check that the welcome mat was already
	//	displayed for the current region combo.  the caller should use this
	//	to update (or create) the cookie
	newRegionCheckedCookie: string

	// Indicates whether the welcome mat should be displayed
	shouldShowWelcomeMat: boolean
}

/**
 * We store a cookie that indicates whether the user has already seen the welcome mat for this
 * two-country combination (your source and destination).
 * @param shipToCountry A two character country code - this is the country the user is in
 * @param thisCountry A two character country code - this is the country of the site itself
 * @returns
 */
const generateWelcomeMatShownString = (shipToCountry: string, thisCountry: string) => `${shipToCountry}>${thisCountry}`

/**
 * The welcome mat is a modal that appears when the user is in a location that does not match the
 * site's region/locale.  For example, if the user visits the US site from the UK or Hungary.  We
 * present an option to the user to navigate to the US site as is, visit the region-specific site if
 * available or, if the site is supported as a border free site, then it will show the US site but
 * show the currency for the country you're in and then show the BorderFree specific page.
 * @param req
 * @returns
 */
export const getWelcomeMatInfo = (
	cookies: Cookies,
	query: URLSearchParams,
	siteList: FullSiteList,
	logger: Logger,
): WelcomeMatInfo => {
	// The BFX country is the country that is stored in a cookie by the BFX script
	const bfxCountryCookie = cookies.get(COOKIE_BFX_COUNTRY)?.toUpperCase()
	const res = getStoredUserPreferenceData() || {}

	// This cookie is added automatically by our middleware
	const geoInfo = cookies.get(COOKIE_CLIENT_COUNTRY)

	// This is the country that the user is trying to go to (based on the locale specified in the URL)
	const requestedCountry = firstStringValue(query.get(QUERY_COUNTRY) || 'US').toUpperCase()

	// The Geo Location country is the one that is pulled from the header (this is done by our CDN - Vercel at the time this was written)
	// User's current country (location)
	const geoLocationCountry = geoInfo?.toUpperCase()

	// The runtime country is the country set by Borderfree except if:
	//      - Current Country is not the same as the Geo Location Country
	//          > if GeoLocation Country is US then someone inside the US is navigating on a non-US site.  This is fine - use the current country
	let shipToCountry = requestedCountry
	if (requestedCountry !== geoLocationCountry) {
		shipToCountry = bfxCountryCookie || geoLocationCountry
	}

	const id = process.env.CONFIG_ENVIRONMENT || 'production'
	if (!globalAccessCountries[id][requestedCountry]) {
		logger.warn({ geoLocationCountry, requestedCountry }, '[WelcomeMat] Invalid requestedCountry')
	}

	const thisCountry = requestedCountry in globalAccessCountries[id] ? requestedCountry : 'US'
	const thisCountryUrl = globalAccessCountries[id][thisCountry].url

	// Check to see if the welcome mat has been shown to the user for this
	//	combination of countries.
	const newRegionCheckedCookie = generateWelcomeMatShownString(shipToCountry, thisCountry)
	const alreadyWelcomed = res?.welcomeMat?.regionChecked === newRegionCheckedCookie

	if (siteList) {
		const siteManager = new SiteManager(siteList)

		const shipToSiteData = siteManager.getSiteData(shipToCountry)
		const isBorderFreeEnabled = siteManager.isBorderFreeSite(shipToCountry)

		// NOTE: I do not know what global access countries refers to here and how it's
		//	different than the countries in the initial list.  This is taken directly from
		//	the code in SFRA.
		const isShipCountryGlobalAccess = shipToCountry in globalAccessCountries[id]

		// The Ship To URL is the URL of the site that the user should be directed to if
		//	they decide to use the site in a way that allows them to get their products shipped
		//	to their country of origin.
		const shipToUrl = isShipCountryGlobalAccess ? globalAccessCountries[id]?.[shipToCountry]?.url : shipToSiteData?.url

		// We should show the welcome mat if the ship country is not the same as the site's country and
		//	we haven't already shown the welcome mat.
		const shouldShowWelcomeMat = shipToCountry !== thisCountry && !alreadyWelcomed

		return {
			isShipCountryGlobalAccess,
			isBorderFreeEnabled,

			// This is the site the user is currently browsing
			thisCountry,
			thisCountryUrl,

			// This is the ship to country information
			shipToCountry,
			shipToUrl,

			newRegionCheckedCookie,

			shouldShowWelcomeMat,
		}
	}

	logger.error('Unable to get site data from UCAPI. Returning default values.')

	return {
		isShipCountryGlobalAccess: false,
		isBorderFreeEnabled: false,
		thisCountry: '',
		thisCountryUrl: '',
		shipToCountry: '',
		shipToUrl: '',
		newRegionCheckedCookie,
		shouldShowWelcomeMat: false,
	}
}
