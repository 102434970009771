import { useCallback, useEffect, useRef, useState } from 'react'

import { PaymentMethod } from '~/lib/enums/basket.enum'
import { useCart } from '../CartProvider/CartProvider'

export default function ThirdPartyPaymentProvider({ children }: React.PropsWithChildren) {
	const didMountRef = useRef(false)
	const [previousGrandTotal, setPreviousGrandTotal] = useState<number | null>(null)
	const { cart, removePaymentInstruments } = useCart()

	// This initially removed all third party payment instruments, however this would cause a bug where a users PayPal
	// instrument would be removed, causing incorrect behavior in the checkout step flow. Only Klarna needs to be removed
	// if the carts grand total changes, so this now only removes the Klarna instrument to remap correct totals
	// https://underarmour.atlassian.net/browse/SB-4402
	// TODO: investigate if we really do need to remove all third party instruments to maintain correct state (SFRA does this today)
	// or if this is a safe solution and no other changes are needed
	const removeKlarnaPaymentMethods = useCallback(() => {
		removePaymentInstruments((pi) => pi.__typename === PaymentMethod.Klarna)
	}, [removePaymentInstruments])

	useEffect(() => {
		if (!cart?.grandTotal) return
		if (!didMountRef.current) {
			didMountRef.current = true
			return
		}

		if (previousGrandTotal === cart.grandTotal) return
		if (!previousGrandTotal) {
			setPreviousGrandTotal(cart?.grandTotal)
			return
		}
		if (previousGrandTotal !== cart.grandTotal) setPreviousGrandTotal(cart?.grandTotal)

		// If the grand total has changed, we need to remove any klarna payment methods so the user
		// is required to re-added them. This allows UACAPI to sync the new total with the klarna payment provider.
		removeKlarnaPaymentMethods()
	}, [cart?.grandTotal, removeKlarnaPaymentMethods, previousGrandTotal])

	return <>{children}</>
}
