import CustomLoader from '~/components/primitives/CustomLoader/CustomLoader'
import { usePathname, useSearchParams } from 'next/navigation'
import { useState, useEffect } from 'react'
import { LINK_CLICK_EVENT, CLOSE_LOADER_EVENT } from '~/lib/client-only/events'
import { isExternalLink, joinPathAndSearchParams, removeTrailingSlash } from '~/lib/utils'

export default function Loading() {
	const [showPageLoader, setShowPageLoader] = useState<boolean>(false)
	const pathname = usePathname()
	const searchParams = useSearchParams()

	const handleCloseLoader = () => {
		setShowPageLoader(false)
	}

	useEffect(() => {
		/**
		 * Once a page url is updated, we would terminate the loading screen and allow the route's
		 * fallback/loading functionality to kick in (IE: skeletons)
		 */
		handleCloseLoader()
	}, [pathname, searchParams])

	useEffect(() => {
		/**
		 * We will only broadcast a loading visual when the urls are not the same, also if the clicked link is not
		 * targeted for an external window. This fires an internal navigation, and would be resolved by the effect above.
		 */
		function handleClickEvent(e) {
			const clickedUrl = removeTrailingSlash(e.detail.url)
			const incomingUrl = joinPathAndSearchParams(removeTrailingSlash(pathname ?? '', true), searchParams || undefined)
			const isExternalTarget = e.detail.target && !['', '_self'].includes(e.detail.target?.trim())
			if (
				clickedUrl !== removeTrailingSlash(incomingUrl) &&
				!isExternalTarget &&
				!isExternalLink(e.detail.url) &&
				!e.detail.keyboardAction
			)
				setShowPageLoader(true)
		}
		// listens to the broadcasted click event from LocaleLink and shows the loader.
		window.addEventListener(LINK_CLICK_EVENT, handleClickEvent)
		window.addEventListener(CLOSE_LOADER_EVENT, handleCloseLoader)
		// suggested Vercel fix to protect about persisted loading when navigating based on the browser buttons
		window.addEventListener('pageshow', handleCloseLoader)

		return () => {
			window.removeEventListener(LINK_CLICK_EVENT, handleClickEvent)
			window.removeEventListener(CLOSE_LOADER_EVENT, handleCloseLoader)
			window.removeEventListener('pageshow', handleCloseLoader)
		}
	}, [pathname, searchParams])

	return <CustomLoader show={showPageLoader} />
}
